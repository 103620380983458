import React, {useState} from "react";
import Footer from "../components/Footer";
import "../css/NCourse.css";
import {FaPlay} from "react-icons/fa";
import Form from "../components/Form";
import "../css/Form.css";
import {LazyLoadImage} from "react-lazy-load-image-component"
// NCourse Videos & Covers
// import video1 from "../videos/financialfreedom-1.mp4";
import video2 from "../videos/contactlisttraining-2.mp4";
import video3 from "../videos/invitingskill-3.mp4";
import video4 from "../videos/followupskill-4.mp4";
import video5 from "../videos/powerofsocialmedia-5.mp4";
import video6 from "../videos/eventspromotionskill-6.mp4";
import video7 from "../videos/presentationskills-7.mp4";
import video8 from "../videos/targetlist-8.mp4";
import video9 from "../videos/handlingobjections-9.mp4";
// ***********************************************************
// import cover1 from "../videos/financialfreedom-1.png";
import cover2 from "../videos/contactlisttraining-2.png";
import cover3 from "../videos/invitingskill-3.png";
import cover4 from "../videos/followupskill-4.png";
import cover5 from "../videos/powerofsocialmedia-5.png";
import cover6 from "../videos/eventspromotionskill-6.png";
import cover7 from "../videos/presentationskills-7.png";
import cover8 from "../videos/targetlist-8.png";
import cover9 from "../videos/handlingobjections-9.png";

function NCourse() {

    // Remove the video Cover when click
    // const [hide, setHide] = useState(false);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(false);
    const [hide5, setHide5] = useState(false);
    const [hide6, setHide6] = useState(false);
    const [hide7, setHide7] = useState(false);
    const [hide8, setHide8] = useState(false);
    const [hide9, setHide9] = useState(false);
    // const handleClick = () => {setHide(true)};
    const handleClick2 = () => {setHide2(true)};
    const handleClick3 = () => {setHide3(true)};
    const handleClick4 = () => {setHide4(true)};
    const handleClick5 = () => {setHide5(true)};
    const handleClick6 = () => {setHide6(true)};
    const handleClick7 = () => {setHide7(true)};
    const handleClick8 = () => {setHide8(true)};
    const handleClick9 = () => {setHide9(true)};

    // Disable inspect element
    window.addEventListener('contextmenu', e => e.preventDefault());

    document.onkeydown = function(e) {
        if(e.keyCode === 123) {
            return false;
        }
        if(e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
            return false;
        }
        if(e.ctrlKey && e.shiftKey && e.keyCode === "C".charCodeAt(0)) {
            return false;
        }
        if(e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
            return false;
        }
        if(e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
            return false;
        }
    }

    const styles = {
        fontSize: '46px',
        marginTop: '7px',
        marginBottom: '30px',
    }

    return (
        <section className="elnouby_videos">
            <section className="fixed"></section>
            <section className="fix_content">

            <Form/>

                <h2 className="elnouby_videos_sub_title sub_title">Video Gallery</h2>
                <h1 className="elnouby_videos_title" style={styles}>Watch Elnouby Videos</h1>
                <section className="videos">
                    <section className="videos_content">
                            {/* <section className="video">
                                <section className={hide ? "video_overlay hide" : "video_overlay"} onClick={handleClick}>
                                <LazyLoadImage src={cover1} style={{width: "100%", height: "100%"}} alt="video cover"/>
                                <section className="center_icon"><FaPlay/></section>
                                </section>
                                <video controls controlsList="nodownload" src={video1} type="video/mp4"></video>
                            </section> */}
                            <section className="video">
                                <section className={hide8 ? "video_overlay hide" : "video_overlay"} onClick={handleClick8}>
                                    <LazyLoadImage src={cover8} style={{width: "100%", height: "100%"}} alt="video cover"/>
                                    <section className="center_icon"><FaPlay/></section>
                                </section>
                                <video controls controlsList="nodownload" src={video8} type="video/mp4"></video>
                            </section>
                            <section className="video">
                                <section className={hide2 ? "video_overlay hide" : "video_overlay"} onClick={handleClick2}>
                                    <LazyLoadImage src={cover2} style={{width: "100%", height: "100%"}} alt="video cover"/>
                                    <section className="center_icon"><FaPlay/></section>
                                </section>
                                <video controls controlsList="nodownload" src={video2} type="video/mp4"></video>
                            </section>
                            <section className="video">
                                <section className={hide3 ? "video_overlay hide" : "video_overlay"} onClick={handleClick3}>
                                    <LazyLoadImage src={cover3} style={{width: "100%", height: "100%"}} alt="video cover"/>
                                    <section className="center_icon"><FaPlay/></section>
                                </section>
                                <video controls controlsList="nodownload" src={video3} type="video/mp4"></video>
                            </section>
                            <section className="video">
                                <section className={hide4 ? "video_overlay hide" : "video_overlay"} onClick={handleClick4}>
                                    <LazyLoadImage src={cover4} style={{width: "100%", height: "100%"}} alt="video cover"/>
                                    <section className="center_icon"><FaPlay/></section>
                                </section>
                                <video controls controlsList="nodownload" src={video4} type="video/mp4"></video>
                            </section>
                            <section className="video">
                                <section className={hide5 ? "video_overlay hide" : "video_overlay"} onClick={handleClick5}>
                                    <LazyLoadImage src={cover5} style={{width: "100%", height: "100%"}} alt="video cover"/>
                                    <section className="center_icon"><FaPlay/></section>
                                </section>
                                <video controls controlsList="nodownload" src={video5} type="video/mp4"></video>
                            </section>
                            <section className="video">
                                <section className={hide6 ? "video_overlay hide" : "video_overlay"} onClick={handleClick6}>
                                    <LazyLoadImage src={cover6} style={{width: "100%", height: "100%"}} alt="video cover"/>
                                    <section className="center_icon"><FaPlay/></section>
                                </section>
                                <video controls controlsList="nodownload" src={video6} type="video/mp4"></video>
                            </section>
                            <section className="video">
                                <section className={hide7 ? "video_overlay hide" : "video_overlay"} onClick={handleClick7}>
                                    <LazyLoadImage src={cover7} style={{width: "100%", height: "100%"}} alt="video cover"/>
                                    <section className="center_icon"><FaPlay/></section>
                                </section>
                                <video controls controlsList="nodownload" src={video7} type="video/mp4"></video>
                            </section>
                            <section className="video">
                                <section className={hide9 ? "video_overlay hide" : "video_overlay"} onClick={handleClick9}>
                                    <LazyLoadImage src={cover9} style={{width: "100%", height: "100%"}} alt="video cover"/>
                                    <section className="center_icon"><FaPlay/></section>
                                </section>
                                <video controls controlsList="nodownload" src={video9} type="video/mp4"></video>
                            </section>
                    </section>
                </section>
                <Footer/>
            </section>
        </section>
    )
}

export default NCourse;