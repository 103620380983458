import React from "react";
import Footer from "../components/Footer";
import "../css/Events.css";
import {FaRegClock, FaLocationArrow} from "react-icons/fa";
import { eventsData } from "../Data";

function Events() {
    return (
        <section className="elnouby_events">
            <section className="fixed"></section>
            <section className="fix_content">
            <h1 className="elnouby_events_title">All Events of Elnouby</h1>
                <section className="all_events">
                    {eventsData.map(eventData => 
                        <section className="event">
                            <section className="event_date">
                                <h2 className="event_date_info">{eventData.eventDateInfo}</h2>
                                <h4 className="event_date_sub_info">{eventData.eventDateSubInfo}</h4>
                            </section>
                            <section className="event_title">
                                <h1 className="title">{eventData.eventTitle}</h1>
                                <p className="sumarry_location">{eventData.eventSummaryLocation}</p>
                            </section>
                            <section className="event_location">
                                <p className="location_name"><FaLocationArrow/> {eventData.eventLocationName}</p>
                            </section>
                            <section className="event_time">
                                <p className="time"><FaRegClock/> {eventData.eventTime}</p>
                            </section>
                            <section className="event_status">{eventData.eventStatus}</section>
                        </section>
                    )}
                </section>
                <Footer/>
            </section>
        </section>
    )
}

export default Events;