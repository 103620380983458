import React from "react";
import "../css/Lists.css";
import {FaFacebookF, FaYoutube, FaInstagram, FaTwitter} from "react-icons/fa";


function SocialMedia() {
    return (
        <ul>
            <li><a href="https://www.facebook.com/Moh.Elnouby"><FaFacebookF/></a></li>
            <li><a href="https://www.instagram.com/moh.elnouby/"><FaInstagram/></a></li>
            <li><a href="https://www.twitter.com/@Mohelnouby1"><FaTwitter/></a></li>
            <li><a href="https://www.youtube.com/channel/UCnDYsJlP7fkXPaIYs1vgKMw/featured"><FaYoutube/></a></li>
        </ul>
    )
}

export default SocialMedia;