import React from "react";
import Footer from "../components/Footer";
import "../css/NCourse.css";
import { startHereData } from "../Data";

function StartHere() {

    // Remove the video Cover when click
    // const removeOverlay = () => {
    //     document.querySelector('.video_overlay').remove()
    // }

    const styles = {
        fontSize: '46px',
        marginTop: '7px',
        marginBottom: '30px',
    }
    
    return (
        <section className="elnouby_videos">
            <section className="fixed"></section>
            <section className="fix_content">
                <h2 className="elnouby_videos_sub_title sub_title">Start Here Videos</h2>
                <h1 className="elnouby_videos_title" style={styles}>Start here for free</h1>
                <section className="videos">
                    <section className="videos_content">
                    {startHereData.map(shData => 
                            <section className="video">
                                {/* <section className="video_overlay" onClick={removeOverlay}>
                                    <img src={shData.cover} alt="video cover"/>
                                    <section className="center_icon"><FaPlay/></section>
                                </section> */}
                                <video controlsList="nodownload" src={shData.video} type="video/mp4" autoPlay loop muted></video>
                            </section>
                        )}
                    </section>
                </section>
                <Footer/>
            </section>
        </section>
    )
}

export default StartHere;