import React from "react";
import Footer from "../components/Footer";
import "../css/PhotoAlbum.css";
import {Link} from "react-router-dom";
import ShareAlbum from "../components/ShareAlbum";
import { leadershipData } from "../Data";
import {LazyLoadImage} from "react-lazy-load-image-component"

function Leadershipcamp() {
    const photoDesc = ["Elnouby, Leadership Camp, Sharm El-Shaikh.", <br/>, "September 2021"];
    return (
        <section className="photo_album">
            <section className="fixed"></section>
            <section className="fix_content">
                <section className="photo_album_info">
                    <h1 className="photo_album_title">Leadership Camp</h1>
                    <Link to="/Media">Media</Link>
                </section>
                <article className="more_info">
                    <p>On 30th, September 2021 – Mohamed El Nouby Leadership Camp 2021, Sharm El-Shaikh - Egypt.</p>
                    <p>Photography by: Mohamed Ramadan.</p>
                </article>
                <ShareAlbum/>

                <section className="album_photos">
                    {leadershipData.map(lsData => 
                        <section className="photo">
                            <section className="overlay">
                                <h3>{photoDesc}</h3>
                            </section>
                            <LazyLoadImage src={lsData.image} style={{width: "100%", height: "auto"}} alt="album"/>
                        </section>
                    )} 
                </section>
                <Footer/>
            </section>
        </section>
    )
}

export default Leadershipcamp;