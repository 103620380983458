import React, { useEffect, useState } from "react";
import './App.css';
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Elnouby from "./pages/Elnouby";
import NCourse from "./pages/NCourse";
import Media from "./pages/Media";
import Events from "./pages/Events";
import Contact from "./pages/Contact";
import Stepupevent from "./pages/Stepupevent";
import Monstersevent from "./pages/Monstersevent";
import Newgeneration from "./pages/Newgeneration";
import Leadershipcamp from "./pages/Leadershipcamp";
import StartHere from "./pages/StartHere";
import { FaChevronUp } from "react-icons/fa";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

function App() {

  // Show chevronUp when scroll down
  const [showScrollup, setShowScrollUp] = useState(false);

  window.addEventListener('scroll', function() {
    window.scrollY >= 130 ? setShowScrollUp(true) : setShowScrollUp(false);
  });

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  }, [])

  return (
    <Router>
      <section className="App">
        <NavBar/>
        <section className={showScrollup ? "scrollup showBtn" : "scrollup"} onClick={() => {window.scrollTo({top: 0, left: 0, behavior: "smooth"})}}>
          <FaChevronUp/>
        </section>
        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/Elnouby" exact element={<Elnouby/>}/>
          <Route path="/StartHere" exact element={<StartHere/>}/>
          <Route path="/NCourse" exact element={<NCourse/>}/>
          <Route path="/Media" exact element={<Media/>}/>
          <Route path="/Events" exact element={<Events/>}/>
          <Route path="/Contact" exact element={<Contact/>}/>
          <Route path="/Stepupevent" exact element={<Stepupevent/>}/>
          <Route path="/Monstersevent" exact element={<Monstersevent/>}/>
          <Route path="/Newgeneration" exact element={<Newgeneration/>}/>
          <Route path="/Leadershipcamp" exact element={<Leadershipcamp/>}/>
        </Routes>
      </section>
    </Router>
  );
}

export default App;