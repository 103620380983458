import React from "react";
import "../css/Fix.css";
import "../css/Elnouby.css";
import bio from "../images/elnouby_bio-1.png";
import Footer from "../components/Footer";
import {LazyLoadImage} from "react-lazy-load-image-component"

function Elnouby() {
    return (
        <section className="elnouby_bio">
            <section className="fixed"></section>
            <section className="fix_content">
                <h1 className="elnouby_title">Elnouby Biography</h1>
                <section className="bio">
                    <section className="bio_content">
                        <figure>
                            <LazyLoadImage src={bio} style={{width: "235px", height: "auto"}} alt="Biography"/>
                        </figure>
                        <article>
                            <p>
                                <p>My name is <strong>(Mohammed Elnouby)</strong>, I was born in 1992 in Cairo Egypt.
                                I graduated from Law school and got my bachelor's degree in 2012.</p>
                                <p>I was introduced to the network marketing business right after my graduation. The main reason I was interested in network marketing was that I did not want to work a 9-5 job that would pay me just enough to survive. My family's financial situation was not the best.</p>
                                <p>I wanted to be independent, and accomplish my big goals and dreams. This business was the perfect fit for me because it did not require me to have big capital to start working independently, instead, it required me to work hard every single day, and learn the skills of marketing.</p>

                                <p>I have coached over 10 thousand people in over 20 different countries in the world to master the skills of network marketing and sales. In 2016 I was speaking to over 20,000 people at the biggest conference of network marketing in Stad, Cairo, Egypt.  I'm happy and grateful that I was able to influence thousands of people and change their lives. I'm thankful to God he has put me in the right direction and showed me the way to change my life forever.</p>

                                <p>I believe what separates successful from non-successful people is their vision, belief, and willingness of making a decision, Life is full of opportunities. God did not create us to live the average, think average, or be average. We are able to have the lifestyle we wish for as long as we have the faith we are doing so. You create and choose your destination.</p>

                                <p>Think about your fingerprint, no one can ever have the same fingerprint you have, your life is the same and your story is unique. You have a white sheet of paper to draw the path you want, the life you choose.</p>

                                <p>If you need an advice from me to live a happy life</p>
                                1- Have faith.<br></br>
                                2- Take care of your family.<br></br>
                                3- Work for yourself.<br></br>
                                4- Train your mind every day to read or watch self-improvement videos.<br></br>
                                5- Take care of your physical health.<br></br>
                                6- Don’t fear the future and worry about it, just do the work and god will reward you for it.
                            </p>
                        </article>
                    </section>
                </section>
                <Footer/>
            </section>
        </section>
    );
}

export default Elnouby;