import React from "react";
import {Link} from "react-router-dom";
import "../css/Lists.css";
import icon from "../images/icon-2.png";

function Lists(props) {

    return (
        <ul onClick={() => [props.isMenu ? props.setIsMenu(false) : true, window.scrollTo({top: 0, left: 0, behavior: "smooth"})]}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/Elnouby">Elnouby</Link></li>
            <li><Link to="/StartHere">Start Here</Link></li>
            <li><Link to="/NCourse"><img src={icon} alt="icon"/> Course</Link></li>
            <li><Link to="/Media">Media</Link></li>
            <li><Link to="/Events">Events</Link></li>
        </ul>
    )
}

export default Lists;