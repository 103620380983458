import React from "react";
import { Link } from "react-router-dom";
import "../css/Footer.css";
import SocialMedia from "./SocialMedia";
import icon from "../images/icon-2.png";
import {LazyLoadImage} from "react-lazy-load-image-component"

function Footer() {
    return (
        <footer>
            <section className="footer_lists">
              <ul onClick={() => {window.scrollTo({top: 0, left: 0, behavior: "smooth"})}}>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/Elnouby">Elnouby</Link></li>
                <li><Link to="/StartHere">Start Here</Link></li>
                <li><Link to="/NCourse"><img src={icon} alt="icon"/> Course</Link></li>
                <li><Link to="/Media">Media</Link></li>
                <li><Link to="/Events">Events</Link></li>
                <li><Link to="/Contact">Contact</Link></li>
              </ul>
            </section>
            <section className="footer_social_media">
                <SocialMedia/>
            </section>
            <figure className="footer_figure">
              <LazyLoadImage src={icon} style={{width: "75px"}} alt="logo" draggable="false"/>
            </figure>
            <h6 className="copyrights">{`Copyrights © Mohamed Elnouby ${new Date().getFullYear()} all rigyhts reserved`}</h6>
        </footer>
    )
}

export default Footer;