import React from "react";
import {FaArrowRight} from "react-icons/fa";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import Fullbackground from "../components/Fullbackground";

function Home() {

  const eventDate = new Date("December 10, 2022");
  const nowDate = new Date();

  const daysBetween = eventDate - nowDate;
  const getDays = Math.round(daysBetween) / (1000 * 60 * 60 * 24);
  const daysLeft = getDays.toString().substring(0,2);

    return (
        <section className="home">
          <Fullbackground/>
          {/* Latest Video */}
          <section className="container">
            <section className="latest_video">
              <h2 className="latest_video_title">Latest: Mohamed Elnouby's Video</h2>
              <section className="videos_link"><Link to="/StartHere" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: "smooth"})}}>More Videos</Link><FaArrowRight/></section>
              <section className="video">
                <iframe title="Youtube video" src="https://www.youtube.com/embed/ge8M5jRkkTo"></iframe>
              </section>
            </section>
          </section>

          {/* Upcoming Events */}
          <section className="upcoming_events">
            <section className="upcoming_events_info">
              <h2 className="upcoming_events_title">{nowDate < eventDate ? "K.O Event" : "Upcoming Events"}</h2>
              <p>Follow Elnouby amazing events all over the world</p>
              <p>{nowDate < eventDate ? "K.O event, upcoming in December 9, 2022" : "No events for now, please check again later."}</p>
              {nowDate < eventDate ? <h3>Days Left: <span>{daysLeft}</span></h3> : ""}
            </section>
          </section>
          <Footer/>
        </section>
    );
  }
  
export default Home;