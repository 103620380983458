import React from "react";
import Footer from "../components/Footer";
import "../css/PhotoAlbum.css";
import {Link} from "react-router-dom";
import ShareAlbum from "../components/ShareAlbum";
import { monsterseventData } from "../Data";
import {LazyLoadImage} from "react-lazy-load-image-component"

function Monstersevent() {
    const photoDesc = ["Elnouby, College of Medicine Conference Hall in Cairo", <br/>, "November 2019"];
    return (
        <section className="photo_album">
            <section className="fixed"></section>
            <section className="fix_content">
                <section className="photo_album_info">
                    <h1 className="photo_album_title">Monsters Event</h1>
                    <Link to="/Media">Media</Link>
                </section>
                <article className="more_info">
                    <p>On 8th, November 2019 – Mohamed El Nouby in College of Medicine Conference Hall in Cairo, Egypt.</p>
                    <p>Photography by: Mohamed Ramadan.</p>
                </article>
                <ShareAlbum/>

                <section className="album_photos">
                    {monsterseventData.map(monsterData => 
                        <section className="photo">
                            <section className="overlay">
                                <h3>{photoDesc}</h3>
                            </section>
                            <LazyLoadImage src={monsterData.image} style={{width: "100%", height: "auto"}} alt="album"/>
                        </section>
                    )} 
                </section>
                <Footer/>
            </section>
        </section>
    )
}

export default Monstersevent;