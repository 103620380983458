import React, {useState} from "react";
import icon_2 from "../images/icon-2.png";
import {FaBars, FaShare} from "react-icons/fa";
import Lists from "./Lists";
import SocialMedia from "./SocialMedia";
import "../css/NavBar.css";
import {LazyLoadImage} from "react-lazy-load-image-component"

function NavBar() {

  // Change Navbar background
  const [bgColor, setBgColor] = useState(false);
  const changeBgColor = () => {
    window.scrollY >= 60 ? setBgColor(true) : setBgColor(false);
  }
  window.addEventListener('scroll', changeBgColor);

  // Toggle Menu & Social classes
  const [isMenu, setIsMenu] = useState(false)

  const [isSocial, setIsSocial] = useState(false)

    return (
        <nav className={bgColor ? "navbar_content navbar_bg" : "navbar_content"}>
          <section className="share_nodes" onClick={() => setIsSocial(!isSocial)}><FaShare/></section>
            <figure>
              <LazyLoadImage src={icon_2} style={{width: "40px"}} alt="logo" draggable="false"/>
            </figure>
            <section className={isMenu ? "nav_lists show" : "nav_lists"}>
              <Lists isMenu={isMenu} setIsMenu={setIsMenu}/>
            </section>
            <section className={isSocial ? "nav_social_mdeia show" : "nav_social_mdeia"}>
                <SocialMedia/>
            </section>
          <section className="menu" onClick={() => setIsMenu(!isMenu)}><FaBars/></section>
        </nav>
    )
}

export default NavBar;