import React from "react";
import Footer from "../components/Footer";
import "../css/Contact.css";
import emailjs from '@emailjs/browser';
import {FaUser, FaEnvelope} from "react-icons/fa";

function Contact() {

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_f8sj0ad', 'template_izlpbxc', e.target, 'olxLXLzrUh-uIoGl9')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };

    return (
        <section className="elnouby_contact">
            <section className="fixed"></section>
            <section className="fix_content">
                <h1 className="contact_title">Contact Us</h1>
                    <section className="contact_form">
                        <section className="contact_info">
                            <h3>Where To Find Mohamed Elnouby</h3>
                            <p>Follow Mohamed Elnouby on social media or simply use this contact form to send an email.</p>
                        </section>

                        <form onSubmit={sendEmail} className="form_control">
                            <h3 className="form_title">Send Us A Message</h3>
                            <section className="input_name">
                                <input className="contact_input" type="text" name="user_name" placeholder="Your Name" required></input>
                                <span><FaUser/></span>
                            </section>
                            <section className="input_email">
                                <input className="contact_input" type="email" name="user_email" placeholder="Your Email" required></input>
                                <span><FaEnvelope/></span>
                            </section>
                            <textarea name="message" placeholder="Please enter a message" required></textarea>
                            <button type="submit">Send Email</button>
                        </form>
                    </section>
                <Footer/>
            </section>
        </section>
    )
}

export default Contact