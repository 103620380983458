import React from "react";
import Footer from "../components/Footer";
import "../css/Media.css";
import {Link} from "react-router-dom";
import { mediaData } from "../Data";
import {LazyLoadImage} from "react-lazy-load-image-component"


function Media() {
    return (
        <section className="elnouby_media">
            <section className="fixed"></section>
            <section className="fix_content">
            <h2 className="elnouby_media_sub_title sub_title">See the unforgetable moments of Elnouby</h2>
            <h1 className="elnouby_media_title">Elnouby Photos</h1>
                <section className="all_media">
                    <section className="all_media_content">
                        
                        {mediaData.map(mData =>
                            <section className="media">
                                <section className="media_overlay">
                                    <section className="media_info">
                                        <h2 className="media_event"><Link to={mData.linkTo} onClick={() => {window.scrollTo({top: 0, left: 0, behavior: "smooth"})}}>{mData.mediaEvent}</Link></h2>
                                        <section className="media_date">{mData.mediaDate}</section>
                                    </section>
                                </section>
                                <LazyLoadImage src={mData.image} style={{width: "100%", height: "100%"}} alt="album" draggable="false"/>
                            </section>
                        )}
                    </section>
                </section>
                <Footer/>
            </section>
        </section>
    )
}

export default Media;