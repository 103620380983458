import React, { useEffect, useState } from "react";
import "../css/Fullbackground.css";
import { fullBgData } from "../Data";
import {LazyLoadImage} from "react-lazy-load-image-component";

function Fullbackground() {

    // Auto Change Fullbackground
    const [currenBg, setCurrentBg] = useState(0);
    const fullBgLength = fullBgData.length;
    const autoBg = true;
    let fullBgInterval;

    useEffect(() => {setCurrentBg(0);}, [])

    const nextFullBg = () => {
        setCurrentBg(currenBg === fullBgLength - 1 ? 0 : currenBg + 1 )
    }

    function autoFullBg() {
        fullBgInterval = setInterval(nextFullBg, 10000)
    }

    useEffect(() => {
        if(autoBg) {
            autoFullBg()
        }
        
        return () => clearInterval(fullBgInterval)
    }, [currenBg])

    return (
        <section className="fullbackground">
            {fullBgData.map((fullBg, index) => {
                return (
                    <section className={index === currenBg ? "fullbg current" : "fullbg"} key={index}>
                        {index === currenBg && (
                            <>
                                <LazyLoadImage src={fullBg.image} style={{width: "100%", height: "100%"}} alt="Fullbackground"/>
                                <section className="info_content">
                                    <q className="info">{fullBg.desc}</q>
                                </section>
                            </>
                        )}
                    </section>
                )
            })}
            <section className="vertical_hr"></section>
        </section>
    )
}

export default Fullbackground