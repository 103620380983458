import React from "react";
import {FaFacebookF, FaTwitter} from "react-icons/fa";

function ShareAlbum() {
    return (
        <section className="share_album">
            <section className="share_album_links">
                <span>Share:</span>
                <ul className="share_social">
                    <li><a href={`https://www.facebook.com/sharer/sharer.php?u=https://www.mohamedelnouby.com`} target="_blank" rel="noreferrer"><FaFacebookF/></a></li>
                    <li><a href={`https://twitter.com/intent/tweet?url=https://www.mohamedelnouby.com`} target="_blank" rel="noreferrer"><FaTwitter/></a></li>
                </ul>
            </section>
        </section>
    )
}

export default ShareAlbum;