// Home Fullbackground images
import fullBg1 from "./images/fullbackground1.png";
import fullBg2 from "./images/fullbackground2.png";
import fullBg3 from "./images/fullbackground3.png";
import fullBg4 from "./images/fullbackground4.png";

// Media images
import photo6 from "./images/stepupevent/photo6.png";
import photo1 from "./images/monstersevent/photo1.png";

// Media => Monsters Event images
import monstersevent1 from "./images/monstersevent/photo1.png";
import monstersevent2 from "./images/monstersevent/photo2.png";
import monstersevent3 from "./images/monstersevent/photo3.png";
import monstersevent4 from "./images/monstersevent/photo4.png";
import monstersevent5 from "./images/monstersevent/photo5.png";
import monstersevent6 from "./images/monstersevent/photo6.png";

// Media => Stepup Event images
import stepupevent1 from "./images/stepupevent/photo1.png";
import stepupevent2 from "./images/stepupevent/photo2.png";
import stepupevent3 from "./images/stepupevent/photo3.png";
import stepupevent4 from "./images/stepupevent/photo4.png";
import stepupevent5 from "./images/stepupevent/photo5.png";
import stepupevent6 from "./images/stepupevent/photo6.png";
import stepupevent7 from "./images/stepupevent/photo7.png";
import stepupevent8 from "./images/stepupevent/photo8.png";
import stepupevent9 from "./images/stepupevent/photo9.png";

// Media => New Generation Event images
import newgeneration1 from "./images/newgeneration/1.png";
import newgeneration2 from "./images/newgeneration/2.png";
import newgeneration3 from "./images/newgeneration/3.png";
import newgeneration4 from "./images/newgeneration/4.png";
import newgeneration5 from "./images/newgeneration/5.png";
import newgeneration6 from "./images/newgeneration/6.png";
import newgeneration7 from "./images/newgeneration/7.png";
import newgeneration8 from "./images/newgeneration/8.png";
import newgeneration9 from "./images/newgeneration/9.png";

// Media => Leadership Camp Event images
import leadership1 from "./images/lsc2021/1.png";
import leadership2 from "./images/lsc2021/2.png";
import leadership3 from "./images/lsc2021/3.png";
import leadership4 from "./images/lsc2021/4.png";
import leadership5 from "./images/lsc2021/5.png";
import leadership6 from "./images/lsc2021/6.png";
import leadership7 from "./images/lsc2021/7.png";
import leadership8 from "./images/lsc2021/8.png";
import leadership9 from "./images/lsc2021/9.png";
import leadership10 from "./images/lsc2021/10.png";
import leadership11 from "./images/lsc2021/11.png";
import leadership12 from "./images/lsc2021/12.png";
import leadership13 from "./images/lsc2021/13.png";
import leadership14 from "./images/lsc2021/14.png";
import leadership15 from "./images/lsc2021/15.png";

// Start Here Videos & Covers
import comingsoon from "./videos/comingsoon.mp4";

export const fullBgData = [
    {
        image: fullBg1,
        desc: `Nothing in life is worth Turn your back on and keep going`
    },
    {
        image: fullBg2,
        desc: `Prove Yourself to Yourself Not others`
    },
    {
        image: fullBg3,
        desc: `Make your hustle louder than your mouth`
    },
    {
        image: fullBg4,
        desc: `Your life is your story write well edit often`
    }
]

export const mediaData = [
    {
        linkTo: "/Leadershipcamp",
        image: leadership1,
        mediaEvent: "Leadership Camp",
        mediaDate: "September 2021"
    },
    {
        linkTo: "/Newgeneration",
        image: newgeneration2,
        mediaEvent: "New Generation Private Event",
        mediaDate: "December 2019"
    },
    {
        linkTo: "/Monstersevent",
        image: photo1,
        mediaEvent: "Monsters Event",
        mediaDate: "November 2019"
    },
    {
        linkTo: "/Stepupevent",
        image: photo6,
        mediaEvent: "Step Up Event",
        mediaDate: "July 2019"
    },
]

export const monsterseventData = [
    {
        image: monstersevent1
    },
    {
        image: monstersevent2
    },
    {
        image: monstersevent3
    },
    {
        image: monstersevent4
    },
    {
        image: monstersevent5
    },
    {
        image: monstersevent6
    },
]

export const stepupeventData = [
    {
        image: stepupevent1
    },
    {
        image: stepupevent2
    },
    {
        image: stepupevent3
    },
    {
        image: stepupevent4
    },
    {
        image: stepupevent5
    },
    {
        image: stepupevent6
    },
    {
        image: stepupevent7
    },
    {
        image: stepupevent8
    },
    {
        image: stepupevent9
    },
]

export const newgenerationData = [
    {
        image: newgeneration1
    },
    {
        image: newgeneration2
    },
    {
        image: newgeneration3
    },
    {
        image: newgeneration4
    },
    {
        image: newgeneration5
    },
    {
        image: newgeneration6
    },
    {
        image: newgeneration7
    },
    {
        image: newgeneration8
    },
    {
        image: newgeneration9
    },
]

export const leadershipData = [
    {
        image: leadership1
    },
    {
        image: leadership2
    },
    {
        image: leadership3
    },
    {
        image: leadership4
    },
    {
        image: leadership5
    },
    {
        image: leadership6
    },
    {
        image: leadership7
    },
    {
        image: leadership8
    },
    {
        image: leadership9
    },
    {
        image: leadership10
    },
    {
        image: leadership11
    },
    {
        image: leadership12
    },
    {
        image: leadership13
    },
    {
        image: leadership14
    },
    {
        image: leadership15
    },
]

export const startHereData = [
    {
        video: comingsoon
    },
    {
        video: comingsoon
    },
    {
        video: comingsoon
    },
    {
        video: comingsoon
    },
    {
        video: comingsoon
    },
    {
        video: comingsoon
    },
    {
        video: comingsoon
    },
    {
        video: comingsoon
    },
]

export const eventsData = [
    {
        eventDateInfo: "01",
        eventDateSubInfo: "Aug",
        eventTitle: "Leadership Camp 2022",
        eventSummaryLocation: "Alexandria Desert Road, Egypt",
        eventLocationName: "Alexandria",
        eventTime: "8:00 PM",
        eventStatus: 'Finished'
    },
    {
        eventDateInfo: "01",
        eventDateSubInfo: "Jun",
        eventTitle: "To the Moon Event Online 2022",
        eventSummaryLocation: "Cairo, Egypt",
        eventLocationName: "Cairo",
        eventTime: "7:00 PM",
        eventStatus: 'Finished'
    },
    {
        eventDateInfo: "20",
        eventDateSubInfo: "Mar",
        eventTitle: "IM Trend Event 2022",
        eventSummaryLocation: "Lebanon",
        eventLocationName: "Lebanon",
        eventTime: "3:00 PM",
        eventStatus: 'Finished'
    },
]