import React from "react";
import {FaCrown} from "react-icons/fa";

function Form() {

    // Premium Form
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(e.target);

        const inputValue = {
            username: data.get('user_name'),
            password: data.get('password')
        }
        if(inputValue.username === 'IBO' && inputValue.password === 'Financialfreedom') {
            document.querySelector('.premium_overlay').remove();
        } else {
            document.querySelector('.premium_error').style = "opacity: 1";
        }
        e.target.reset()
    }

    return (
        <section className="premium_overlay">
            <form className="premium_form" onSubmit={handleSubmit}>
                <h2 className="premium_title">Premium <FaCrown/></h2>
                <input className="premium_input" type="text" name="user_name" placeholder="Username" required></input>
                <input className="premium_input" type="password" name="password" placeholder="Password" required></input>
                <p className="premium_error">Wrong Username or Password</p>
                <button type="submit">Log In</button>
            </form>
        </section>
    )
}

export default Form;